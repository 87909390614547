<template>
  <div class="grid-tabs">
    <tab-bar @newRow="dialog = true"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :query="query"
        :filter="filters"
        :annotations="annotations"
        :columns="columns"
        :nested-query="nestedQuery"
        :nested-columns="columns"
        :nested-filters="nestedFilters"
        :componentName="$options.name"
        :contextMenuItems="contextMenuItems"
        :nested-context-menu-items="nestedContextMenuItems"
        :nested-grid="true"
        :is-row-master-function="isRowMasterFunction"
        @ready="ready"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <v-dialog
        v-model="dialog"
        lazy
        persistent
    >
        <v-card v-if="dialog">
            <v-card-title class="green darken-4">
              <h3 class="white--text" v-if="isEditing">Edit account {{ account.name }}</h3>
              <h3 class="white--text" v-else>{{ account.parent_account_id ? 'Add a sub account' : 'Add an account' }}</h3>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-xl>
                <v-layout wrap>
                  <v-flex>
                    <v-text-field
                      autofocus
                      v-model="account.account_number"
                      box
                      :error-message="numberErrorMessage"
                      @blur="checkAccountNumber"
                    >
                      <template #label>
                        Account Number
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="account.name"
                      box
                      :error-message="nameErrorMessage"
                      @blur="checkAccountName"
                    >
                      <template #label>
                        Account Name
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="account.description"
                      box
                      hint="Optional"
                      persistent-hint
                    >
                      <template #label>
                        Description
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex shrink>
                    <v-select
                      v-model="account.account_type"
                      label="Account Type"
                      :items="['ASSETS', 'EQUITY', 'LIABILITIES']"
                    ></v-select>
                  </v-flex>
                  <v-flex shrink>
                    <accounting-account-autofill
                      :account="account.parent_account_id"
                      @updated="account.parent_account_id = $event.value"
                    >
                      <template #label>
                        Parent Account Lookup...
                      </template>
                    </accounting-account-autofill>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-layout row justify-start align-center>
                <t-alert :message="message" :type="messageType"/>
                <v-spacer></v-spacer>
                <v-btn
                  :color="added ? 'info' : 'error'"
                  @click="resetDialog"
                >{{ added ? 'Close' : 'Cancel' }}</v-btn>
                <v-btn
                  color="success"
                  :loading="adding"
                  :disabled="added"
                  @click="isEditing ? addAccount('edit') : addAccount('add')"
                >
                  Submit
                </v-btn>
              </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tabBarWrapper from '@/components/wrappers/tabBarWrapper'
import gridWrapper from '@/components/wrappers/gridWrapper'
import grid from '@/components/mixins/grid'
import {
  CONTEXT_MENU__ACCOUNTING_COA,
  CONTEXT_MENU__ACCOUNTING_COA_NESTED
} from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__ACCOUNTING_COA, KB_SHORTCUT__ACCOUNTING_COA_NESTED } from '@/lib/agGridKbShortCuts'
import { COLUMNS__ACCOUNTING_COA } from '@/lib/agGridColumnConfiguration'
import {
  CREATE_ACCOUNTING_ACCOUNT,
  GET_ACCOUNTING_ACCOUNTS__GRID,
  GET_ACCOUNTING_ACCOUNTS__LIST, UPDATE_ACCOUNTING_ACCOUNT
} from '@/api/graphql/Constants/Accounting'
import AccountingAccountsAutocomplete from '@/components/autocompletes/AccountingAccountsAutocomplete'
import tAlert from '@/components/notifications/tAlert'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'

export default {
  name: 'ChartOfAccounts',
  mixins: [grid],
  components: {
    't-alert': tAlert,
    'tab-bar': tabBarWrapper,
    'grid-wrapper': gridWrapper,
    'accounting-account-autofill': AccountingAccountsAutocomplete
  },
  data () {
    return {
      query: GET_ACCOUNTING_ACCOUNTS__GRID,
      filters: [
        {
          key: 'parent_account__isnull',
          value: true
        }
      ],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'sub_accounts'
        }
      ],
      columns: COLUMNS__ACCOUNTING_COA,

      nestedQuery: GET_ACCOUNTING_ACCOUNTS__GRID,
      nestedFilters: (id) => [{ key: 'parent_account__id', value: id }],

      contextMenuItems: params => CONTEXT_MENU__ACCOUNTING_COA(params, this.contextMenuCallback),
      nestedContextMenuItems: params => CONTEXT_MENU__ACCOUNTING_COA_NESTED(params, this.contextMenuCallback),

      // dialog
      dialog: false,
      isEditing: false,
      account: {
        name: '',
        account_number: '',
        description: '',
        account_type: '',
        parent_account_id: ''
      },
      added: false,
      adding: false,
      message: '',
      messageType: 'info',

      numberErrorMessage: '',
      nameErrorMessage: ''
    }
  },
  methods: {
    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__ACCOUNTING_COA(params, this.contextMenuCallback), KB_SHORTCUT__ACCOUNTING_COA_NESTED(params, this.contextMenuCallback))
    },

    isRowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    contextMenuCallback ({ functionToRun, params, data, dataKey }) {
      if (dataKey) this[dataKey] = data
      if (functionToRun) this[functionToRun](params)
    },

    resetDialog () {
      this.dialog = false
      for (const key in this.account) {
        this.account[key] = ''
      }
      if (this.account.id !== undefined) delete this.account.id
      this.nameErrorMessage = ''
      this.numberErrorMessage = ''
      this.message = ''
      this.messageType = 'info'
      this.isEditing = false
    },

    resultHandler ({ message, type }) {
      this.message = message
      this.messageType = type
    },

    async addAccount (operation = 'add') {
      const mutation = operation === 'edit' ? UPDATE_ACCOUNTING_ACCOUNT : CREATE_ACCOUNTING_ACCOUNT
      try {
        this.validateAccount()
        this.adding = true
        await this.$apollo.mutate({
          mutation: mutation,
          variables: {
            input: this.account
          }
        })
        await this.$store.dispatch('data/changeRefresh', { bool: true })
        this.resultHandler({
          message: `Successfully ${operation}ed account`,
          type: 'success'
        })
        this.added = true
      } catch (error) {
        const message = error.message ? error.message : error
        this.resultHandler({
          message: message,
          type: 'error'
        })
      } finally {
        this.adding = false
      }
    },

    validateAccount () {
      if (!this.account.name) throw new Error('Specify a name')
      if (!this.account.account_number) throw new Error('Specify a number')
      if (Number(this.account.parent_account_id) === Number(this.account.id)) throw new Error('Account cannot be its own parent')
      if (!this.account.account_type) throw new Error('Select an account type')
    },

    async checkAccountNumber () {
      const isValid = await this.checkAccount('account_number', this.account.account_number)
      if (!isValid) {
        this.numberErrorMessage = 'This name is already used!'
      } else this.numberErrorMessage = ''
    },

    async checkAccountName () {
      const isValid = await this.checkAccount('name', this.account.name)
      if (!isValid) {
        this.nameErrorMessage = 'This name is already used!'
      } else this.nameErrorMessage = ''
    },

    async checkAccount (key, value) {
      const response = await this.$apollo.query({
        query: GET_ACCOUNTING_ACCOUNTS__LIST,
        variables: {
          input: {
            filters: [{ key: key, value: value }]
          }
        }
      })
      return !response.data.accounting_accounts
    },

    prepareToAddSubAccountNumber ({ params }) {
      this.account.parent_account_id = params.node.data.id
      this.dialog = true
    },

    prepareToEdit ({ params }) {
      const account = params.node.data
      this.isEditing = true
      this.account.id = account.id
      this.account.parent_account_id = params?.data?.id ?? ''
      this.account.name = account.name
      this.account.account_type = account.account_type
      this.account.description = account.description
      this.account.account_number = account.account_number
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
