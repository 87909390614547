




































import {
  computed,
  ComputedRef,
  defineComponent,
  onActivated,
  onBeforeMount,
  provide,
  Ref,
  ref
} from '@vue/composition-api'
import { ApolloClients, useQuery, UseQueryReturn } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { GET_ACCOUNTING_ACCOUNTS__LIST } from '@/api/graphql/Constants/Accounting'
import {
  Accounting_Account,
  ManyQueryInput,
  Query,
  QueryAccounting_AccountsArgs
} from '@/models/generated/graphql/ErpBackend'

export default defineComponent({
  name: 'AccountingAccountsAutocomplete',
  props: {
    account: {
      // type is of ID which is String or Number
      required: true
    },
    attach: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessages: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const localAccount: ComputedRef<number> = computed({
      get (): number {
        return Number(props.account)
      },
      set (value: any): void {
        emit('updated', { value: `${value.id}`, name: value.name, number: value.account_number })
      }
    })

    const search: Ref<string> = ref('')
    const enabled = ref(false)

    const accounts: Ref<Accounting_Account[]> = ref([])
    const { loading, onResult }: UseQueryReturn<Query, any> = useQuery(
      GET_ACCOUNTING_ACCOUNTS__LIST,
      () => ({
        input: {
          filters: [
            {
              key: 'name__icontains',
              value: search.value,
              or: { key: 'account_number__icontains', value: search.value }
            }
          ]
        }
      }),
      () => ({
        enabled: enabled.value
      })
    )
    onResult(result => {
      if (result) {
        if (result.data) {
          if (result.data.accounting_accounts) {
            accounts.value = result.data.accounting_accounts ?? []
          }
        }
      }
    })

    function Refetch () {
      enabled.value = true
      setTimeout(() => {
        enabled.value = false
      }, 100)
    }

    onBeforeMount(async () => {
      if (props.account) {
        const response = await apolloClient.query({
          query: GET_ACCOUNTING_ACCOUNTS__LIST,
          variables: {
            input: {
              filters: [{ key: 'id', value: props.account }]
            }
          }
        })
        accounts.value.push(...response.data.accounting_accounts)
      }
    })

    return {
      localAccount,
      accounts,
      loading,
      search,
      Refetch
    }
  }
})
