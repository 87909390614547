<template>
  <div id="tab-bar">
    <div
      id="router-bar"
    >
      <v-btn
        v-for="(tab, index) in currentTabs"
        :key="index"
        active-class="active"
        :class="{ active: $route.name === tab.to }"
        :id="tab.name"
        :to="{ name: tab.to }"
        small
        style="margin-left: -2px"
        :dark="darkMode"
      >
        {{ tab.name }}
      </v-btn>
    </div>
    <div id="grid-buttons-container">
      <v-layout row justify-end align-center>
        <slot name="parent-buttons"/>
      </v-layout>
      <!-- Add Button -->
      <v-btn
        v-if="showAddButton"
        small
        data-cy="new-data-form-button"
        class="primary--text"
        @click="newRow"
      >
        <v-icon left small class="fal fa-plus mr-2" color="primary"></v-icon>
        Create
      </v-btn>
      <!-- refresh button -->
      <v-btn
        @click="refresh = true"
        small
        :loading="refresh"
        active-class="grid-button-active"
        class="primary--text"
        data-cy="grid-refresh-button"
      >
        Refresh
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabBar',
  computed: {
    extended () {
      return this.$store.state.grid.extended
    },

    filterApplied: {
      get: function () {
        return this.$store.state.grid.hasFilter
      },
      set: function (newValue) {
        this.$store.dispatch('grid/changeHasFilter', { bool: newValue })
      }
    },

    refresh: {
      get () {
        return this.$store.state.data.refresh
      },
      set (bool) {
        this.$store.dispatch('data/changeRefresh', { bool: bool })
      }
    },

    darkMode () {
      return this.theme === 'dark'
    },

    theme () {
      return this.$store.state.profile.theme
    },

    showAddButton () {
      const path = this.$route.fullPath
      return (!path.includes('inventory') && !path.includes('rma') && !path.includes('breakdown')) || path.includes('master') || path.includes('accounting')
    }
  },
  watch: {
    height: function (value) {
      this.maxHeight = value * 0.80
    }
  },
  data () {
    return {
      selected: [],

      currentTabs: [],
      width: '',
      height: '',
      maxHeight: '',

      // Filter shit
      filterMenu: false,
      savedFilters: [
        { name: 'My Parts', action: 'blah' },
        { name: '30 Days Old', action: 'blah' },
        { name: 'Most Expensive', action: 'blah' }
      ],
      allowFilterNaming: false,
      filterName: '',
      canSaveFilter: false,
      saveFilterText: 'Name Filter',
      customFilter: null,

      fakeFilters: [
        {
          name: 'Part Class',
          filters: [
            'Desktop',
            'Server',
            'Memory',
            'Peripherials',
            'Storage',
            'Processors',
            'Graphics',
            'Mobile',
            'Networking'
          ],
          columnName: 'class'
        }
      ],

      tabName: null,
      tab: null,
      tabs: {
        inventory: [
          { name: 'items', to: 'item' },
          { name: 'systems', to: 'system' },
          // { name: 'supplies', to: 'supplies' },
          // { name: 'internal', to: 'internal', searchText: 'Item Name' },
          // { name: 'software', to: 'software', searchText: 'Name, Vendor' },
          // { name: 'services', to: 'service', searchText: 'Name' },
          { name: 'history', to: 'history' },
          { name: 'master', to: 'master' }
        ],
        purchases: [
          { name: 'purchases', to: 'purchaseOrders' },
          { name: 'rma', to: 'prma' },
          { name: 'work orders', to: 'workOrders' }
        ],
        sales: [
          { name: 'sales', to: 'saleOrders' },
          { name: 'rma', to: 'srma' },
          { name: 'quotes', to: 'quotes' }
        ],
        clients: [
          { name: 'client', to: 'client' }
        ],
        logs: [
          { name: 'logs', to: 'logs' }
        ],
        assembly: [
          { name: 'BreakDown', to: 'breakdown' },
          { name: 'Buildup', to: 'buildup' }
        ],
        accounting: [
          { name: 'Ledger', to: 'ledger' },
          { name: 'Payable', to: 'payable' },
          { name: 'Receivable', to: 'receivable' },
          { name: 'Summary', to: 'summary' }
        ]
      }
    }
  },
  methods: {
    /* setExtended (bool, tab) {
      this.tabName = tab.name
      this.$store.dispatch('grid/setExtended', { extended: bool })
    }, */

    newRow () {
      this.$emit('newRow')
    },

    getCurrentArrays () {
      const route = this.$route.fullPath.split('/')[1]
      this.currentTabs = this.$router.options.routes
        .filter(r => {
          return r.path.includes(`/${route}/`) && !r.path.includes('new')
        })
        .map(r => ({
          to: r.name,
          name: r.jumpName || r.name
        }))
    },

    keyUpChecker (event) {
      if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.nodeName) === -1) {
        if ((event.key === '+' || (event.code === 'Equal' && event.shiftKey)) && this.showAddButton) {
          this.$emit('newRow')
        } else if (event.key === 'Escape') {
          this.$store.dispatch('grid/resetDialog')
          this.$store.dispatch('grid/resetSheet')
        }
      }
    }
  },
  beforeMount () {
    this.getCurrentArrays()
    this.tab = this.currentTabs.filter(tab => {
      return tab.to === this.$route.name
    })[0]
    this.tabName = this.tab.name
  },
  mounted () {
    window.addEventListener('keyup', this.keyUpChecker)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyUpChecker)
  }
}
</script>
